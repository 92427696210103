import React, { useState } from "react";

import backButton from "../../assets/panel/backButton.svg";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import specializations from "../../list.json";
import axios from 'axios';
import Button from "@material-ui/core/Button";









export default function PacientCard({...props}) {


  const [state, setState]= React.useState({
    oppinionForInsurance: "",
    nextStep: undefined,
    diagnosis: ""
  });

  const {
    oppinionForInsurance,
    nextStep,
    diagnosis
  }=state;

  console.log("Ovaj je za Pacient Card", state);
  const [dataView, setDataView] = React.useState('vprasalnik');
  const data = props.location.state.pacient;
  console.log("Pacient Card", props);


  const renderQuestionaryResults = (data) => {
    return (
    <div className="grey-container pacientCard">
    <h5>Termin pacienta</h5>
    <div class="pacientCardRow">
      <div className="key">
      Dne
      </div>
      <div className="value">
      {moment(data.sessionStartTime).format('L')}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Čas
      </div>
      <div className="value">
      {moment(data.sessionStartTime).hours()}:{moment(data.sessionStartTime).minutes()}h
      </div>
    </div>
    <h5>Podatki pacienta</h5>
    <div class="pacientCardRow">
      <div className="key">
      Ime:
      </div>
      <div className="value">
      {(data.firstName == undefined) ? "Brez Imena" : data.firstName}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Priimek:
      </div>
      <div className="value">
      {(data.lastName == undefined) ? "" : data.lastName}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Elektronski naslov:
      </div>
      <div className="value">
      {data.email}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Telefonska številka:
      </div>
      <div className="value">
      {data.phone}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Rojstni datum:
      </div>
      <div className="value">
      {data.length > 1 ? data.birthday.substring(0,10) : ""}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Spol:
      </div>
      <div className="value">
      {data.gender == "male" ? "moški" : "ženski"}
      </div>
    </div>
    <h5>Odgovori pacienta</h5>
    {data.questions.map((data, i) =>
      <div class="pacientCardRowReversed">
        <div className="key">
        {i + 1}. {data.question}
        </div>
        <div className="value">
        {data.answer}
        </div>
      </div>
    )}


      {data.painLevel == "undefined" || data.painLevel == 0 ? <></> :
          <div class="pacientCardRowReversed">
          <div className="key">
          {data.questions.length + 1}. Kakšna je jakost bolečine (1-10)?
          </div>
          <div className="value">
          {data.painLevel}
              </div>
        </div>
        }

    <div class="pacientCardRowReversed">
      <div className="key">
      {data.questions.length + 2}. Uploaded files
      </div>
      <div className="value">
      {data.file == undefined  ? <></> : <div className="drfile" >
                {     data.file.map(file => (
    <li class="fieluploaded" key={file.path}>
     <a target="_blank" href={`${process.env.REACT_APP_API_URL}sessions/file/${file.filename}`} > {file.originalname}</a>
    </li>
  ))}
       </div>
       }
          </div>
    </div>

  </div>)
  }


  const renderAnamnesis = (data, props) => {
    return (
      <>
        <h5 style={{ fontWeight: "bold" }}>Vaša mnenje</h5>
        <div className="grey-container">
          <div class="pacientCardRowReversed" style={{ margin: "none" }}>
            <TextField
              className="value"
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Vaša mnenje"
              multiline
              fullWidth
              margin="normal"
              disabled={!props.location.state.editable}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={data.diagnosis}
              onChange={(e) =>
                setState({ ...state, diagnosis: e.target.value })
              }
            />
          </div>
        </div>
        <hr style={{ margin: "3em 0 1em 0" }} />
        <h5 style={{ fontWeight: "bold" }}>Napotitev k specialistu</h5>
        <div className="grey-container">
          <div class="pacientCardRowReversed" style={{ margin: "none" }}>
            {/* <TextField className="value"
                    id="outlined-full-width"
                    style={{ margin: 8,}}
                    placeholder="Napotitev k specialisti"
                    multiline
                    fullWidth
                    margin="normal"
                   disabled={  !props.location.state.editable   }

                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={data.nextStep}
                  /> */}

            <input
              id="country"
              name="country"
              required
              list="country-list"
              className="value"
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Napotitev k specialistu"
              multiline
              fullWidth
              margin="normal"
              disabled={!props.location.state.editable}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={ (e) => {
                const verify = specializations.filter((item) => item == e.target.value)
                if(e.target.value.length < 1){
                  setState({ ...state, nextStep: undefined})
                } else {
                  if(verify == e.target.value) {
                    setState({ ...state, nextStep: e.target.value})} else {
                     setState({ ...state, nextStep: undefined})
                    }
                }
               } }
                              title="Please select a country"
              style={{ width: "100%", padding: "0.5em" }}
            />
            <datalist id="country-list" style={{ width: "100%" }}>
              {specializations.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </datalist>
          </div>
        </div>
        <hr style={{ margin: "3em 0 1em 0" }} />
        <h5 style={{ fontWeight: "bold" }}>Mnenje za zavarovalnico</h5>
        <div className="grey-container">
          <div className="pacientCardRowReversed" style={{ margin: "none" }}>
            <TextField
              className="value"
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Mnenje za zavarovalnico"
              multiline
              fullWidth
              margin="normal"
              disabled={!props.location.state.editable}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={data.oppinionForInsurance}
              onChange={(e) =>
                setState({ ...state, oppinionForInsurance: e.target.value })
              }
            />
          </div>
        </div>

        {!props.location.state.editable ? (
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              margin: "2em 0",
            }}
          >
            <Button
              className={(diagnosis.length < 1 || nextStep == undefined) ? "button-grey" : "button-red"}
              style={
                (diagnosis.length < 1 || nextStep == undefined)
                  ? { color: "#000", background: "#efefef", marginTop: "0" }
                  : { marginTop: "0", background: "#5cc8bd" }
              }
              disabled={
                diagnosis.length < 1 || nextStep == undefined ? true : false
              }
              onClick={() => sendSurvey(state, props)}
            >
              Oddajte
            </Button>
            <Button className="button-grey" style={{ marginLeft: "1em" }}>
              Prekliči
            </Button>
          </div>
        )}
      </>
    );
  }


  const sendSurvey = (state, props) => {
    console.log('Update survey with survey id', props);
    axios.get(`${process.env.REACT_APP_API_URL}sessions/${data._id}`,{
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.log(response);
      axios.patch(`${process.env.REACT_APP_API_URL}sessions/${data._id}`, {
        id: data._id,
        diagnosis: diagnosis,
        nextStep: nextStep,
        oppinionForInsurance: oppinionForInsurance,
        "callStatus" : "Completed"
      },
      {
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
        }
      }
      
      )
      console.log(props)
      props.history.replace('pacienti')
    })};

  return (
    <>
      <div className="section">
        <div className="section-container" style={{marginBottom: "2em"}}>
          <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "2em 0",
                cursor: "pointer"
              }}
              onClick={() => props.history.replace('pregled')}
            >
              <img src={backButton}></img>
              <h3
                style={{ weight: "400", marginLeft: "20px", fontSize: "24px" }}
              >
                Pojdi nazaj
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                marginTop: "0.5em",
                justifyContent: "space-between",
                alignItems:"center",
              }}
            >
              <h3>Pacient: {data.firstName} {data.lastName}</h3>
              <div
                className="filters"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {(data.options.policyIssuer == "Merkur") ? 
                  <>
                    <h5 onClick={() => setDataView('vprasalnik')} style={(dataView == "vprasalnik") ? {color: "#C4385B"} : {}}>Vprašalnik</h5>
                <h5 onClick={() => setDataView('mnenje')} style={(dataView == "mnenje") ? {color: "#C4385B"} : {}}>Mnenje</h5>
                    </>
                 : <></>}
              </div>
            </div>
          </div>
          <hr />
          <div
            className="section-content" style={{height: "auto"}}
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            {(data.options.policyIssuer == "Merkur") ? (dataView == "vprasalnik") ? renderQuestionaryResults(data) : renderAnamnesis(data,props) : renderAnamnesis(data, props)}
          </div>
        </div>
      </div>
    </>
  );
}
