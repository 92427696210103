import React, {useState, useEffect} from "react";
import vprasalnik from "../../../../assets/panel/vprasalnik.svg";
import moment from "moment";
import axios from "axios"

export default function PacientsTable({ ...props }) {
  const [popUp, setPopUp] = React.useState(false);
  const data = props.data;
  const past = props.past;
  const currentDateStart = moment().subtract(1, 'day').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();

  console.log("Pacienti" , data)
console.log("Prvi Props", props.editable);

  const renderPopUp = () => {
    
    return (
      <div className="popUp">
        <h3>Zakaj želite preklicati ta video posvet?</h3>
        <textarea placeholder="Napišite zakaj želite preklicati ta video posvet...."></textarea>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "2em 0",
          }}
        >
          <div
            className="button-grey"
            style={{ marginRight: "1em" }}
            onClick={() => setPopUp(false)}
          >
            Prekliči
          </div>
          <div className="button-red" style={{ marginTop: "0" }}>
            Oddajte
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="nyd-activityTable-pacients" style={{ marginTop: "20px"}}>
        <div
          className="nyd-activityTable-item"
          style={{
            background: "transparent",
            textAlign: "center",
            justifyContent: "normal",
          }}
        >
          <div         style={{
            textAlign: "left",
          }}> Ime in priimek</div>
          <div style={{ flex: 1 }}> Datum rojstva</div>
          <div style={{ flex: 1 }}> Čas posveta</div>
          <div style={{ flex: 1 }}> Mnenje</div>
        </div>
      </div>
      <div className="nyd-activityTable-pacients">

        {data.slice(0, 10).map((pacient) => 
                <div
                className="nyd-activityTable-item"
                style={{
                  textAlign: "center",
                  justifyContent: "normal",
                  padding: "1.5% 2%",
                  fontSize: "20px",
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(25%, 3fr))'
                }}
              >
                <div
                  style={{  fontWeight: "bold", textDecoration: "underline", textAlign: "left"}}
                >
                {(pacient.firstName == undefined) ? "Nima podataka" : `${pacient.firstName} ${pacient.lastName}`}
                </div>
                <div> {pacient.birthday}</div>
                {past ? 
                 <div> {moment().diff(currentDateStart, 'days', false) === 0 ? 'Danas' : (moment().diff(currentDateStart, 'days', false) === 1) ? 'Včeraj' : 'Pred ' + moment().diff(currentDateStart, 'days', false) + ' dnevi'} </div>
                  : 
                  <div> {moment(pacient.sessionStartTime).hours()}:{moment(pacient.sessionStartTime).minutes()}</div>
                }
                <div style={{  color: "#5CC8BD", cursor: "pointer" }} onClick={() => props.props.history.replace('pacientcard', {'pacient':pacient, 'editable': props.editable})}>{past ? 'Oglej' : 'Uredi'}</div>
            
              </div>
              )}
      </div>
    </>
  );
}
