import React, { useState, useContext, useEffect } from "react";
import nemateAktivnosti from "../../assets/panel/nemateAktivnosti.svg";
import ActivityTable from "./partials/activityTable/ActivityTable";
import CustomCalendar from "./partials/calendar/Calendar";
import {store} from '../../Store';
import axios from 'axios';
import moment from "moment";

export default function Settings({ ...props }) {
  const [dataView, setDataView] = React.useState("termini");
  const [localState, setState] = React.useState([]);
  const {state, dispatch} = useContext(store);
  const [sessions, setSessions] = useState([]);

  const {firstName, lastName, email, phone, username, password, doctorType, birthday, gender} = localState;

  console.log(state.data.user)
  let birthDate = state.data.user.birthday.substr(0,10)
  console.log(
    state.data.user._id,
     (firstName != undefined) ? firstName : state.data.user.firstName,
     (lastName != undefined) ? lastName : state.data.user.lastName,
     (email != undefined) ? email : state.data.user.email,
     (phone != undefined) ? phone : state.data.user.phone,
     (password != undefined) ? password : state.data.user.password,
     (doctorType != undefined) ? doctorType : state.data.user.doctorType,
     (birthday != undefined) ? birthday : birthDate,
     (gender != undefined) ? gender : state.data.user.gender
  )


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}availabilities/doctor/${state.data.user._id}`).then((response) => {
      const sessions = [];
    
      response.data.map((data) => 
      sessions.push({
        date: new Date(data.startTime), 
        title: 'Session'
      })
      );
      setSessions(sessions);
      console.log('Doctor availabilities per date', sessions)
    });
  }, [])


  const updateUser = () => {
    axios.put(`${process.env.REACT_APP_API_URL}users/${state.data.user._id}`, {
      userId: state.data.user._id,
      firstName: (firstName != undefined) ? firstName : state.data.user.firstName,
      lastName: (lastName != undefined) ? lastName : state.data.user.lastName,
      email: (email != undefined) ? email : state.data.user.email,
      phone: (phone != undefined) ? phone : state.data.user.phone,
      password: (password != undefined) ? password : state.data.user.password,
      doctorType: (doctorType != undefined) ? doctorType : state.data.user.doctorType,
      birthday: (birthday != undefined) ? birthday : state.data.user.birthday,
      gender: (gender != undefined) ? gender : state.data.user.gender
  }).then((response) => {
    console.log(response)
    dispatch({
      type: "SET_DATA",
      payload: {
        isAuthenticated: true,
        user: response.data,
        accessToken: state.data.access_token
      }
    })

    const loggedUser = {
      isAuthenticated: true,
      user: response.data,
      accessToken: state.data.access_token
    };

    localStorage.setItem('user', JSON.stringify(loggedUser));
    console.log(state)
  });
  }


  const renderAppointmentScheduler = () => {
    const timezone = new Date().toString().substr(35);
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <h3 className="heading-text">Vaš časovni pas: <strong>{timezone.slice(0, timezone.length - 1)}</strong></h3>
          </div>
        </div>
        <div className="grey-container-calendar">
          <CustomCalendar user={state.data.user._id}/>
        </div>
        <div className="data-grid">
          <div className="item">
            <div className="item-text">
              <h3 className="heading-text">Največje število dogodkov na dan</h3>
              <p>
                Poljubno lahko nastavite največje število dogodkov, napovedanih
                v enem dnevu
              </p>
            </div>
            <div className="item-number-green">24</div>
          </div>
          <div className="item">
            <div className="item-text">
              <h3 className="heading-text">Časovni presledki med dogodki</h3>
              <p>
                Zavoljo prostega časa za pripravo med enim in drugim dogodkom,
                si poljubno nastavite časovni razmak med dvema dogodkoma
              </p>
            </div>
            <div className="item-number">20min</div>
          </div>
          <div className="item">
            <div className="item-text">
              <h3 className="heading-text">
                Najmanjši čas od rezervacije do dogodka
              </h3>
              <p>Poljubno si preprečite last-minute rezervacije terminov</p>
            </div>
            <div className="item-number">3h</div>
          </div>
          <div className="item">
            <div className="item-text">
              <h3 className="heading-text">
                Najdaljši čas od rezervacije do dogodka
              </h3>
              <p>Poljubno si vnaprej preprečite rezervacije terminov</p>
            </div>
            <div className="item-number">3dni</div>
          </div>
        </div>
      </div>
    );
  };

  const renderPersonalSettings = () => {
    return (
      <>
        <div className="doctorCard">
          <div>
            <h5>
              <strong>Vaše profesionalno področje</strong>
            </h5>
            <div class="doctorCardRow">
              <input
                type=""
                placeholder={state.data.user.doctorType}
                value={doctorType}
                onChange={(e) => setState({...localState, doctorType: e.target.value })}
              ></input>
            </div>
          </div>
          <div>
            <h5>
              <strong>Vaš e-poštni naslov</strong>
            </h5>
            <div class="doctorCardRow">
              <input
              placeholder={state.data.user.email}
                type=""
                value={email}
                onChange={(e) => setState({...localState, email: e.target.value })}
              ></input>
            </div>
          </div>
           <div>
            <h5>
              <strong>Vaše ime</strong>
            </h5>
            <div class="doctorCardRow">
              <input
              placeholder={state.data.user.firstName}
                type=""
                value={firstName}
                onChange={(e) => setState({...localState, firstName: e.target.value })}
              ></input>
            </div>
          </div>
          <div>
            <h5>
              <strong>Vaš priimek</strong>
            </h5>
            <div class="doctorCardRow">
              <input
              placeholder={state.data.user.lastName}
                type=""
                value={lastName}
                onChange={(e) => setState({ ...localState,lastName: e.target.value })}
              ></input>
            </div>
          </div>          <div>
            <h5>
              <strong>Vaša telefonska številka</strong>
            </h5>
            <div class="doctorCardRow">
              <input
              placeholder={state.data.user.phone}
                type=""
                value={phone}
                onChange={(e) => setState({...localState, phone: e.target.value })}
              ></input>
            </div>
          </div>
          <div>
            <h5>
              <strong>Geslo</strong>
            </h5>
            <div class="doctorCardRow">
              <input
                placeholder="************"
                type="password"
                value={password}
                onChange={(e) => setState({...localState, password: e.target.value })}
              ></input>
            </div>
          </div>
          <div>
            <h5>
              <strong>Vaš rojstni datum</strong>
            </h5>
            <div class="doctorCardRow">
              <input
                value={birthday}
                type="date"
                onChange={(e) => setState({...localState, birthday: e.target.value })}
              ></input>
              {console.log(state.data.user.birthday.substr(0,10))}
            </div>
          </div>
          <div>
            <h5>
              <strong>Spol</strong>
            </h5>
            <div class="doctorCardRow">
              <input
              placeholder={state.data.user.gender}
                type=""
                value={gender}
                onChange={(e) => setState({...localState, gender: e.target.value })}
              ></input>
            </div>
          </div>  
        </div>
        <div className="button-red" style={{ marginTop: "0" }} onClick={() => updateUser()}>
        Posodobi
        </div>
      </>
    );
  };

  const renderActivity = () => {
    return (
      <>
        <div
          className="section-header"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "normal",
            alignItems: "normal",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3>Nastavitve</h3>
            </div>
          </div>
          <div
            className="filters-nastavitve"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h5
              onClick={() => setDataView("termini")}
              style={dataView == "termini" ? { color: "#C4385B", cursor: "pointer" } : {cursor: "pointer"}}
            >
              Prosti termini
            </h5>
            <h5
              onClick={() => setDataView("osebneNastavitve")}
              style={dataView == "osebneNastavitve" ? { color: "#C4385B", cursor: "pointer"} : {cursor: "pointer"}}
            >
              Osebne nastavitve
            </h5>
          </div>
        </div>
        <hr />
        <div 
          className="section-content"
      
          style={{ height: "auto", color: "#707070", paddingTop: "30px" }}
        >
          {dataView == "termini"
            ? renderAppointmentScheduler()
            : renderPersonalSettings()}
        </div>
      </>
    );
  };

  return (
    <div className="section">
      <div className="section-container">{renderActivity()}</div>
    </div>
  );
}
