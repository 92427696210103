import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Login from "./components/admin/Login";
import Admin from "./components/admin/Admin"


// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    console.log("ROUTER PROPS", this.props);

    return (
      <Router>
        <Switch>
          {/* admin routes */}
          <Route exact path="/" component={Login}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/pregled" component={Admin}></Route>
          <Route path='*' exact>
            <Redirect path="/"></Redirect>
          </Route>
        </Switch>
      </Router>
    );
  }
}
