//import useState hook to create menu collapse state
import React, { useContext, useState } from "react";
//import react pro sidebar components
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import nastavitve from "../../../../assets/panel/nastavitve.svg";
import pacienti from "../../../../assets/panel/pacienti.svg";
import pregled from "../../../../assets/panel/pregled.svg";
import { store } from "../../../../Store";
import "./SideBar.css";

const SideBar = () => {
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);
  const { state, dispatch } = useContext(store);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <>
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader></SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem
                icon={
                  <img
                    src={pregled}
                    className={
                      menuIndex == 0 ? "active-menu-item" : "disabled-menu-item"
                    }
                  ></img>
                }
                onClick={() => setMenuIndex(0)}
              >
                <Link
                  to="/pregled"
                  style={menuIndex == 0 ? { color: "#C4385B" } : {}}
                >
                  Pregled
                </Link>
              </MenuItem>
              {/* <MenuItem icon={<img src={posvet}></img>}><Link to="posvet">Video posvet</Link></MenuItem> */}
              <MenuItem
                icon={
                  <img
                    src={pacienti}
                    className={
                      menuIndex == 1 ? "active-menu-item" : "disabled-menu-item"
                    }
                  ></img>
                }
                onClick={() => setMenuIndex(1)}
              >
                <Link
                  to="pacienti"
                  style={menuIndex == 1 ? { color: "#C4385B" } : {}}
                >
                  Pacienti
                </Link>
              </MenuItem>
              {state.data.user.doctorType === "KitsDoctor" ? (
                <MenuItem
                  icon={
                    <img
                      src={pacienti}
                      className={
                        menuIndex == 2
                          ? "active-menu-item"
                          : "disabled-menu-item"
                      }
                    ></img>
                  }
                  onClick={() => setMenuIndex(2)}
                >
                  <Link
                    to="/napotnice"
                    style={menuIndex == 2 ? { color: "#C4385B" } : {}}
                  >
                    Napotnice
                  </Link>
                </MenuItem>
              ) : null}
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem
                icon={
                  <img
                    src={nastavitve}
                    className={
                      menuIndex == 2 ? "active-menu-item" : "disabled-menu-item"
                    }
                  ></img>
                }
                onClick={() => setMenuIndex(3)}
              >
                <Link
                  to="/nastavitve"
                  style={menuIndex == 3 ? { color: "#C4385B" } : {}}
                >
                  Nastavitve
                </Link>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;
