import React, { useState, useEffect, useContext } from "react";
import axios from "axios"
import moment from "moment"
import {store} from '../../Store';

import backButton from "../../assets/panel/backButton.svg";
import PacientsTable from "./partials/pacientsTable/PacientsTable";



const renderAnamnesis = () => {
  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>Vaša mnenje</h5>
      <div className="grey-container">
        <div
          class="pacientCardRowReversed"
          style={{ margin: "0", padding: "0%" }}
        >
          <textarea
            className="value"
            style={{ height: "5em" }}
            placeholder="Napišite vašo anamnezo za pacienta...."
          ></textarea>
        </div>
      </div>
      <hr style={{ margin: "3em 0 1em 0" }} />
      <h5 style={{ fontWeight: "bold" }}>Napotitev k specialisti</h5>
      <div className="grey-container">
        <div class="pacientCardRowReversed" style={{ margin: "none" }}>
          <div className="value">- Lorem ipsum dolor sit amet </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          margin: "2em 0",
        }}
      >
        <div className="button-red" style={{ marginTop: "0" }}>
          Oddajte
        </div>
        <div className="button-grey" style={{ marginLeft: "1em" }}>
          Prekliči
        </div>
      </div>
    </>
  );
};

export default function Pacients({ ...props }) {

  const [pastsSessions, setPastSessions] = useState([]);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [numberOfAllSessions, setNumberAllSessions] = useState([]);

  const {state, dispatch} = useContext(store);


  const currentDateEnd = moment().set({hour:25,minute:0,second:0,millisecond:0}).toISOString();
  const currentDateStart = moment().set({hour:3,minute:0,second:0,millisecond:0}).toISOString();
  const dayBeforeCurrentDateEnd = moment().subtract(1, 'day').set({hour:25,minute:0,second:0,millisecond:0}).toISOString();
  const currentTime = new Date().toISOString();


  const twoDaysAhead = moment().add(3, 'day').set({hour:1,minute:0,second:0,millisecond:0}).toISOString();
  const startOfMonth = moment().clone().startOf('month').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();

  useEffect(async () => {
    const upcomingSessions = await getSessions(state.data.user._id, startOfMonth, currentTime, 100);
    const upcomingSessionsFilterd = [];
    upcomingSessions.map(item => {
      if(item.diagnosis == undefined) {
        upcomingSessionsFilterd.push(item)
    }});

    const upcomingSessionsFilterdSecond = [];
    upcomingSessionsFilterd.map(item => {
      if(item.firstName == undefined) {
        console.log('No firstName', item.firstName)
    } else {
      upcomingSessionsFilterdSecond.push(item)
    }
  });

    const pastSessions = await getSessions(state.data.user._id, startOfMonth, currentTime, 100);

    const pastSessionsFilterd = [];
    pastSessions.map(item => {
      if(item.diagnosis != undefined) {
        pastSessionsFilterd.push(item)
    }});
    setPastSessions(pastSessionsFilterd);

    setUpcomingSessions(upcomingSessionsFilterdSecond);
    setAllSessions(getAllSessionsForDoctor(state.data.user._id))
    console.log("Filtrirani paciuenti", upcomingSessionsFilterdSecond, pastSessionsFilterd)
  }, [])


   const getSessions = async (doctorId, startDate, endDate, limit) => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/doctorsessions/${doctorId}/${startDate}/${endDate}/${limit}`,{
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table(startDate, endDate, response.data)
       return response.data;
    });
  }

  

  const getAllSessionsForDoctor  = async (doctorId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/alldoctorsessions/${doctorId}`,{
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table('All sessions for doctor',response.data.length,  response.data);
      setNumberAllSessions(response.data.length)
       return response.data;
    });
  }


  return (
    <>
      <div className="section" style={{ height: "auto"}}>
        <div
          className="section-container"
          style={{ marginBottom: "2em", padding: "0% 10% 0% 5%", display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center"}}
        >
        <div style={{flex: 3, padding: "0 2%"}}>
        <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "initial",
              }}
            >
              <h3 style={{margin: 'revert'}}>Pacienti</h3>
            </div>
          </div>
          <hr />
          <div
            className="section-content"
            style={{ height: "auto" }}
            style={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <div className="grey-container" style={{ padding: "15px 20px", margin: "0 0 3em 0"}}>
              <h3 style={{ marginBottom: "0", fontWeight: "bold", fontSize:"28px", margin: "15px 0", borderBottom: "4px solid #5CC8BD", display: "inline-block", borderRadius: "2px", paddingBottom: "5px"}}>Odprto</h3>
              <PacientsTable  editable={true} props={props} data={upcomingSessions} />

            </div>

            <div className="grey-container">
              <h3 style={{ marginBottom: "0", fontWeight: "bold", fontSize:"28px", margin: "15px 0", borderBottom: "4px solid #C4385B", display: "inline-block", borderRadius: "2px", paddingBottom: "5px"}}>Zaprto</h3>
              <PacientsTable props={props} editable={false}  data={pastsSessions} past={true}/>
            </div>
          </div>
        </div>
        <div style={{flex:1, padding:"3px ​2%"}}>
        <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "0em",
              }}
            >
            </div>
          </div>
          <div
            className="section-content"
            style={{ height: "auto" }}
            style={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <div className="counter-container" style={{ background: "#5CC8BD"}}>
                <div style={{background: "#fff", fontSize: "32px", color: "#000", fontWeight:"bold", borderRadius: "12px", padding: "2% 9%", marginRight: "5%"}}>{upcomingSessions.length}</div>
              <h3 style={{ fontSize:"18px", margin: "0", display: "inline-block", borderRadius: "2px", paddingBottom: "0"}}>Število pacientov v obravnavi</h3>
            </div>

            <div className="counter-container">
            <div style={{background: "#fff", fontSize: "32px", color: "#000", fontWeight:"bold", borderRadius: "12px", padding: "2% 6%", marginRight: "5%"}}>{pastsSessions.length}</div>
              <h3 style={{ fontSize:"18px", margin: "0", display: "inline-block", borderRadius: "2px", paddingBottom: "0", color:"#000"}}>Število zakjučenih pacientov</h3>
            </div>

            <div className="counter-container">
            <div style={{background: "#fff", fontSize: "32px", color: "#000", fontWeight:"bold", borderRadius: "12px", padding: "2% 6%", marginRight: "5%"}}>{numberOfAllSessions}</div>
              <h3 style={{ fontSize:"18px", margin: "0", display: "inline-block", borderRadius: "2px", paddingBottom: "0", color:"#000"}}>Skupno število pacientov</h3>            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
