import axios from "axios";
import React from "react";

export default function ReportTable({ ...props }) {
  const [popUp, setPopUp] = React.useState(false);
  const [cancelationReason, setCancelationReason] = React.useState(false);
  const data = props.data;
  const [selectedSession, setSeletedSession] = React.useState();

  const openLink = (link) => {
    window.open(link);
  };

  console.log("test", props);

  const updateSession = (selectedSession) => {
    console.log("Update survey with survey id", selectedSession);
    axios.patch(
      `${process.env.REACT_APP_API_URL}sessions/${selectedSession._id}`,
      {
        id: selectedSession._id,
        rating: selectedSession.rating,
        ratingOptions: selectedSession.ratingOptions,
        comment: selectedSession.drugo,
        willingToPay: selectedSession.willingToPay,
        pacientId: selectedSession.pacientId,
        doctorId: selectedSession.doctorId,
        firstName: selectedSession.firstName,
        lastName: selectedSession.lastName,
        email: selectedSession.email,
        birthday: selectedSession.birthday,
        gender: selectedSession.gender,
        cancelationInitiator: "Doctor",
        callStatus: "Canceled",
        cancelationExplenation: cancelationReason,
      },
      {
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_LOCAL_KEY}`,
        },
      }
    );
  };

  return (
    <>
      {props.data.map((report) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fff",
              padding: "15px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <h1>
                  Pacient: {report.pacient.first_name}{" "}
                  {report.pacient.last_name}
                </h1>
                <h1 style={{ marginLeft: "10px" }}>Status: {report.status}</h1>
                <h1 style={{ marginLeft: "10px" }}>ID: {report._id}</h1>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <h1
                  onClick={() =>
                    props.props.history.push(`pacient`, {
                      pacient: report,
                      editable: props.editable,
                    })
                  }
                >
                  Pregled
                </h1>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
