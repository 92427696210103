import React from "react";
import vprasalnik from "../../../../assets/panel/vprasalnik.svg";
import merkurfav from "../../../../assets/panel/merkurfav.svg";
import msdfav from "../../../../assets/panel/msdfav.svg";
import moment from "moment";
import axios from "axios"

export default function ActivityTable({ ...props }) {
  const [popUp, setPopUp] = React.useState(false)
  const [cancelationReason, setCancelationReason] = React.useState(false)
  const data = props.data;
  const [selectedSession, setSeletedSession] = React.useState()

  const openLink = (link) => {
    window.open(link);
  }

  console.log( "test", props)



  const updateSession = (selectedSession) => {
    console.log('Update survey with survey id', selectedSession);
    axios.patch(`${process.env.REACT_APP_API_URL}sessions/${selectedSession._id}`, {
      id: selectedSession._id,
      rating: selectedSession.rating,
      ratingOptions: selectedSession.ratingOptions,
      comment: selectedSession.drugo,
      willingToPay: selectedSession.willingToPay,
      pacientId: selectedSession.pacientId,
      doctorId: selectedSession.doctorId,
      firstName: selectedSession.firstName,
      lastName: selectedSession.lastName,
      email: selectedSession.email,
      birthday: selectedSession.birthday,
      gender: selectedSession.gender,
      cancelationInitiator: "Doctor",
      callStatus: "Canceled",
      cancelationExplenation: cancelationReason
    },
    {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    },)
  }

  const renderPopUp = (selectedSession) => {
    return (
      <>
       <div className="test"></div>
       <div className="popUp">
        <h3>Zakaj želite preklicati ta video posvet?</h3>
        <textarea
        onChange={(e) => {setCancelationReason(e.target.value)
        console.log(cancelationReason)}}
        placeholder="Napišite zakaj želite preklicati ta video posvet....">
        </textarea>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "2em 0",
          }}
        >
          <div className="button-grey" style={{ marginRight: "1em" }} onClick={() => setPopUp(false)}>
            Prekliči
          </div>
          <div className="button-red" style={{ marginTop: "0" }} onClick={() => {
            setPopUp(false);
            updateSession(selectedSession);
            props.props.history.replace('pregled');
            }}>
            Oddajte
          </div>
        </div>
      </div>
      </>
    );
  };


  
  return (
    <>
    {console.log(props)}
    {console.log(selectedSession)}
      <div className="nyd-activityTable">

        {props.data.slice(0, 10).map((data, i) => {
        console.log("This is the data from a activity table!" )
        const currentDate = data.sessionDate.substr(0, 10);
        currentDate.substr(0, 10);
        return (
                <div className="nyd-activityTable-item">
                {popUp ? renderPopUp(selectedSession) : <></>}
                <div><b>{currentDate}</b> - {moment(data.sessionStartTime).hours()}:{moment(data.sessionStartTime).minutes()} - {moment(data.sessionStartTime).add(15, 'minutes').hours()}:{moment(data.sessionStartTime).add(15, 'minutes').minutes()}</div>
                <div>
                  <strong>Video posvet z {data.firstName == undefined ? "pacientom" : `${data.firstName} ${data.lastName}`}</strong>
                </div>
            <div className="nyd-activityTable-options">
               <div className="nyd-activityTable-options option">
                    {data.options.policyIssuer == "Merkur" ? <img src={merkurfav}></img> :  <img src={msdfav}></img> }
                  </div>
                  <div className="nyd-activityTable-options option">
                    <img src={vprasalnik} onClick={() => props.props.history.replace('pacientcard', {'pacient': data, 'editable': props.editable} )}></img>
                  </div>
                  {props.call ?  data.callStatus == "Canceled" ? <></> :
                  <div className="nyd-activityTable-options option">
                  <p style={{ color: "#C4385B" }} onClick={() => props.props.history.replace('pacientcard', {'pacient': data, 'editable': props.editable})}>Mnenje</p>
                </div> :  data.callStatus == "Canceled" ? <></> : <div className="nyd-activityTable-options option">
                  <p style={{ color: "#C4385B" }} onClick={() => {
                    setPopUp(true);
                    setSeletedSession(data);
                    }}>Prekliči</p>
                </div>}
                   {data.callStatus == "Canceled" ? 
                   <div className="nyd-activityTable-options option">
                   <p style={{ color: "#C4385B" }}>- Klic odpovedan s strani {data.cancelationInitiator == "Doctor" ? "zdravnika" : "pacienta"}</p>
                 </div> : 
                   props.call ? <></> : <div className="nyd-activityTable-options option">
                    {(!data.doctorUrl || data.doctorUrl.length > 1) ? <p onClick={() => openLink(data.doctorUrl)} style={{color: "#5CC8BD"}}>Pridruži se klicu ></p> : <p disabled>Pridruži se klicu ></p>}
                  </div>}
                </div>
              </div>
      )}
      )}
      </div>
    </>
  );
}
