import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReportTable from "../../components/admin/partials/activityTable/ReportTable";
import { store } from "../../Store";

export default function Overview({ ...props }) {
  const [dataView, setDataView] = React.useState("odprto");
  const { state, dispatch } = useContext(store);

  console.log(state);

  const [unresolvedReports, setunresolvedReports] = useState([]);
  const [resolvedReports, setResolvedReports] = useState([]);

  const getReports = async (doctorId, status, limit) => {
    return axios
      .get(
        `https://dev.merkur.nyd.care/api/kits/601033244999444f44378138/${status}/${limit}`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_LOCAL_KEY}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        return response.data;
      });
  };

  useEffect(async () => {
    setunresolvedReports(
      await getReports(state.data.user._id, "analyzed", 100)
    );
    setResolvedReports(await getReports(state.data.user._id, "completed", 100));
  }, []);

  const renderUpcomingEvents = () => {
    return (
      <>
        <br></br>
        <div className="grey-container">
          <ReportTable props={props} editable={true} data={unresolvedReports} />
        </div>
      </>
    );
  };

  const renderResolvedEvents = () => {
    return (
      <>
        <br></br>
        <div className="grey-container">
          <ReportTable props={props} editable={false} data={resolvedReports} />
        </div>
      </>
    );
  };

  const renderActivity = () => {
    return (
      <>
        <div
          className="section-header"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "normal",
            alignItems: "normal",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3>Napotnice</h3>
            </div>
          </div>
          <div
            className="filters"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h5
              onClick={() => setDataView("odprto")}
              style={dataView == "odprto" ? { color: "#C4385B" } : {}}
            >
              Odprto
            </h5>
            <h5
              onClick={() => setDataView("zaprtno")}
              style={dataView == "zaprtno" ? { color: "#C4385B" } : {}}
            >
              Zaprtno
            </h5>
          </div>
        </div>
        <hr />
        <div
          className="section-content"
          style={{ height: "auto", color: "#707070" }}
        >
          {dataView == "odprto"
            ? renderUpcomingEvents()
            : renderResolvedEvents()}
        </div>
      </>
    );
  };

  return (
    <div className="section">
      <div className="sivo"> </div>

      <div className="section-container">{renderActivity()}</div>
    </div>
  );
}
