import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import enlargeScreen from "../../assets/enlargeScreen.svg";
import { store } from "../../Store";
import Overview from "./Overview";
import PacientCard from "./PacientCard";
import Pacients from "./Pacients";
import Header from "./partials/header/Header";
import SideBar from "./partials/sidebar/SideBar";
import ReportCard from "./ReportCard";
import Reports from "./Reports";
import Settings from "./Settings";

export default function App({ ...props }) {
  const { state, dispatch } = useContext(store);
  const history = useHistory();

  const getUser = () => {
    const user = localStorage.getItem("user");
    console.log(user);
    const userJson = JSON.parse(user);
    console.log(userJson);
    if (user === null) {
      return false;
    }
    return userJson;
  };

  useEffect(() => {
    let rememberedUser = getUser();
    console.log("USER ", rememberedUser);

    if (rememberedUser !== false) {
      dispatch({
        type: "SET_DATA",
        payload: {
          isAuthenticated: true,
          user: rememberedUser.user,
          accessToken: rememberedUser.access_token,
        },
      });
      history.replace("pregled");
    }

    console.log("Should happen before render", state.data.isAuthenticated);
  }, []);

  if (state.data.isAuthenticated) {
    return (
      <div className="doctor-panel-resize-app">
        <div className="resize-window">
          <div className="resize-window-header">
            <img src={enlargeScreen}></img>
            <h3>Spremenite velikost okna!</h3>
          </div>
          <div className="resize-window-description">
            <p>
              Za prikaz vsebine na naši spletni strani vas prosimo, da povečate
              velikost okna spletnega brskalnika.
            </p>
          </div>
        </div>
        <div className="doctor-panel">
          {console.log("State inside of admin panel", state)}
          <Router>
            <Header />
            <SideBar />
            <Switch>
              {/* <Route exact path="/admin" component={Admin}></Route> */}
              <Route exact path="/pregled" component={Overview}></Route>
              <Route exact path="/pacientcard" component={PacientCard}></Route>
              <Route exact path="/pacienti" component={Pacients}></Route>
              <Route exact path="/napotnice" component={Reports}></Route>
              <Route path="/pacient/" component={ReportCard}></Route>
              <Route exact path="/nastavitve" component={Settings}></Route>
            </Switch>
          </Router>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/login"></Redirect>;
  }
}
