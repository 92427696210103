import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React from "react";
import backButton from "../../assets/panel/backButton.svg";

export default function PacientCard({ ...props }) {
  const [state, setState] = React.useState({
    oppinionForInsurance: "",
    nextStep: undefined,
    diagnosis: "",
  });
  const [dataView, setDataView] = React.useState("pregled");

  const { oppinionForInsurance, nextStep, diagnosis } = state;

  console.log("Ovaj je za Pacient Card", state);
  const data = props.location.state.pacient;
  console.log("Pacient Card", props);

  const renderQuestionaryResults = (data) => {
    console.log("Ovaj je data", data);
    return (
      <div className="grey-container pacientCardFull">
        <h5>Podatki pacienta</h5>

        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              padding: "0 2%",
            }}
          >
            <div class="pacientCardRow">
              <div className="key">Ime:</div>
              <div className="value">
                {data.pacient.first_name == undefined
                  ? "Brez Imena"
                  : data.pacient.first_name}
              </div>
            </div>
            <div class="pacientCardRow">
              <div className="key">Priimek:</div>
              <div className="value">
                {data.pacient.last_name == undefined
                  ? ""
                  : data.pacient.last_name}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              padding: "0 2%",
            }}
          >
            <div class="pacientCardRow">
              <div className="key">Elektronski naslov:</div>
              <div className="value">
                {data.pacient.email == undefined ? "" : data.pacient.email}
              </div>
            </div>
            <div class="pacientCardRow">
              <div className="key">Telefonska številka:</div>
              <div className="value">
                {data.pacient.phone == undefined ? "" : data.pacient.phone}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAnamnesis = (data, props) => {
    return (
      <>
        <div className="grey-container-reserved">
          <div class="pacientCardRowReversed" style={{ margin: "none" }}>
            <h5 style={{ fontSize: "22px", fontWeight: "bold" }}>
              Vaše mnenje{" "}
            </h5>
            <TextField
              className="value"
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Vaša mnenje"
              multiline
              fullWidth
              margin="normal"
              disabled={!props.location.state.editable}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={data.doctorOppinion.oppinion}
              onChange={(e) =>
                setState({ ...state, diagnosis: e.target.value })
              }
            />
          </div>
        </div>

        {!props.location.state.editable ? (
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              margin: "2em 0",
            }}
          >
            <Button
              className={diagnosis.length < 1 ? "button-grey" : "button-red"}
              style={
                diagnosis.length < 1
                  ? { color: "#000", background: "#efefef", marginTop: "0" }
                  : { marginTop: "0", background: "#5cc8bd" }
              }
              disabled={diagnosis.length < 1 ? true : false}
              onClick={() => sendSurvey(state, props)}
            >
              Oddajte
            </Button>
            <Button className="button-grey" style={{ marginLeft: "1em" }}>
              Prekliči
            </Button>
          </div>
        )}
      </>
    );
  };

  const sendSurvey = (state, props) => {
    console.log("Update survey with survey id", props);
    axios
      .get(`${process.env.REACT_APP_API_URL}kits/${data._id}`, {
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_LOCAL_KEY}`,
        },
      })
      .then((response) => {
        console.log(response);
        axios.patch(
          `${process.env.REACT_APP_API_URL}kits/${data._id}`,
          {
            id: data._id,
            doctorOppinion: { oppinion: diagnosis },
            status: "completed",
          },
          {
            headers: {
              "x-api-key": `${process.env.REACT_APP_API_LOCAL_KEY}`,
            },
          }
        );
        console.log(props);
        props.history.replace("napotnice");
      });
  };

  const renderNapotnica = () => {
    return (
      <>
        <div className="grey-container">
          <h5 style={{ fontSize: "22px", fontWeight: "bold" }}>
            Podatki napotnice
          </h5>
          <div class="pacientCardRow" style={{ padding: "0 2%" }}>
            <div
              className="value-code"
              style={{ padding: "2%!important", flex: "none!important" }}
            >
              <img style={{ width: "45vw" }} src={`${data.barcode}`}></img>
            </div>
            <div
              className="value-qrcode"
              style={{
                padding: "2%!important",
                flex: "none!important",
                height: "-webkit-fit-content!important",
              }}
            >
              <img src={`${data.qrcode}`}></img>
            </div>
          </div>
        </div>

        <div
          className="section-content"
          style={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {renderQuestionaryResults(data)}
        </div>

        <br></br>

        <div className="grey-container">
          <h5 style={{ fontSize: "22px", fontWeight: "bold" }}>Izvid</h5>
          <div class="pacientCardRow" style={{ padding: "0 2%" }}>
            <div
              className="value-pdf"
              style={{ padding: "2%!important", flex: "none!important" }}
            >
              <img style={{ width: "64vw" }} src={`${data.pdf}`}></img>
            </div>
          </div>
        </div>

        <br></br>

        <div className="grey-container">
          <h5 style={{ fontSize: "22px", fontWeight: "bold" }}>
            Odgovori pacienta
          </h5>
          {data.questions.map((data, i) => (
            <div class="pacientCardRowReversed">
              <div className="key">
                {i + 1}. {data.question}
              </div>
              <div className="value" style={{ padding: "10px" }}>
                {data.answer}
              </div>
            </div>
          ))}
        </div>
        <br />
        <br />
      </>
    );
  };

  return (
    <>
      <div className="section">
        <div className="section-container" style={{ marginBottom: "2em" }}>
          <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "2em 0",
                cursor: "pointer",
              }}
              onClick={() => props.history.replace("napotnice")}
            >
              <img src={backButton}></img>
              <h3
                style={{ weight: "400", marginLeft: "20px", fontSize: "24px" }}
              >
                Pojdi nazaj
              </h3>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                marginTop: "0.5em",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>
                Pacient: {data.pacient.first_name} {data.pacient.last_name}
              </h3>
              <div
                className="filters"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <h5
                  onClick={() => setDataView("pregled")}
                  style={dataView == "pregled" ? { color: "#C4385B" } : {}}
                >
                  Pregled
                </h5>
                <h5
                  onClick={() => setDataView("mennje")}
                  style={dataView == "mennje" ? { color: "#C4385B" } : {}}
                >
                  Mennje
                </h5>
              </div>
            </div>
          </div>
          <hr />
          <br></br>
          {dataView == "pregled"
            ? renderNapotnica()
            : renderAnamnesis(data, props)}
        </div>
      </div>
    </>
  );
}
