import { useMonthlyCalendar } from '@zach.codes/react-calendar';
import { startOfMonth, subHours, subMonths, format, getYear, addMonths } from 'date-fns'

import backButton from '../../../../assets/panel/backButtonCalendar.svg'
import nextButton from '../../../../assets/panel/nextButtonCalendar.svg'

export const CalendarNav = () => {
  let { currentMonth, onCurrentMonthChange } = useMonthlyCalendar();

  return (
    <div className="flex justify-space-between flex-row" style={{marginBottom: "1em", padding: "0 0.4em 0 0"}}>
            <div className="flex" style={{flex: 1}}>
        <p style={{fontSize: "24px"}}>
        <strong>        
        {format(
          currentMonth,
          'LLLL, yyyy'
        )}</strong>
        </p>
      </div>
        <div className="flex justify-end" style={{flex: 1}}>
        <button
        onClick={() => onCurrentMonthChange(subMonths(currentMonth, 1))}
        className="cursor-pointer"
      >
        <img src={backButton}></img>
      </button>
      <button
        style={{marginLeft: "50px"}}
        onClick={() => onCurrentMonthChange(addMonths(currentMonth, 1))}
        className="cursor-pointer"
      >
        <img src={nextButton}></img>
      </button>
        </div>
    </div>
  );
};
